import { ICandidate } from './candidate.entity';

const JSONParse = (object: any, returnError: any) => {
  try {
    return JSON.parse(object);
  } catch (error) {
    return returnError;
  }
};

const mapper = (item: any) => {
  // Map correct data
  try {
    return {
      ...item,
      status: (item.status === 'approve') ?  'approved' : item.status,
      skills: item.skills,
      answersVoxerInterview: item.answersVoxerInterview,
      step3: { ...item.step3, file: JSONParse(item.step3.file, []) },
      createdAt: new Date(item.createdAt),
      isReconsidered: item.isReconsidered ? 'reconsidered' : null
    } as ICandidate;
  } catch (error) {
    return item as ICandidate;
  }
};

export const candidateMapper = (res: any) => {
  return mapper(res);
};

export const candidateListMapper = (res: any) => {
  // Map correct list data
  return res.map((i: any) => mapper(i)) as ICandidate[];
};
