import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  private readonly tag = 'ErrorHandlerService -';

  constructor() { }

  handleError(error: any) {
    console.error(this.tag, error);
  }

}
