import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICandidate } from 'src/app/business/entities/candidate/candidate.entity';
import { BaseManager } from 'src/app/business/managers/base.manager';
import { APIS } from 'src/app/constants/api.constant';
import { StorageService } from 'src/app/services/storage/storage.service';
import { candidateMapper } from '../../entities/candidate/candidate.entity.mapper';

@Injectable({
  providedIn: 'root',
})
export class GetCandidateManager extends BaseManager {
  constructor(
    protected override httpC: HttpClient,
    protected override storageService: StorageService
  ) {
    super(httpC, storageService);
  }

  getCandidate(qParams: any) {
    return this.call<ICandidate>(APIS.CANDIDATES_GET, undefined, qParams);
  }

  protected override mapper(res: any) {
    const item = res[0];
    return candidateMapper(item);
  }
}
