import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { StorageKeyENUM } from 'src/app/services/storage/storage.enum';
import { StorageService } from 'src/app/services/storage/storage.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { environment } from '../../../../../../../environments/environment';
import { CandidatesService } from '../../../../../pages/candidates/candidates.service';

const { SERVER_URL } = environment;
@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: []
})
export class VerticalHeaderComponent implements OnInit {

  @Input() sidebartoggle: MatSidenav | any;
  public notifications: any[] = [];
  closeWebsocket: any[] = [];

  constructor(
    private http: HttpClient, private router: Router,
    private ws: WebsocketService, private candidatesService: CandidatesService,
    private storageService: StorageService
  ) { }


  ngOnInit(): void {
    this.refresh();
    this.ws.listen('create_candidate', this.refresh),
      this.ws.listen('update_note', () => { console.log("si"); })
  }



  refresh = () => {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService.getItem(StorageKeyENUM.ACCESS_TOKEN)}`
    });
    const id = this.storageService.getItem(StorageKeyENUM.USER_ID);
    this.http.get(`${SERVER_URL}/user?id=${id}`, { headers })
      .subscribe((resp: any) => {
        const user = resp[0];
        this.notifications = user.notifications;
      });
  }

  getBtnNotificationClass = () => {
    const thereIsNotifications = this.notifications.length > 0;
    const classesWithNotifications = thereIsNotifications ?
      'animate__animated animate__wobble' :
      '';
    return `m-r-5 position-relative ${classesWithNotifications}`;
  }

  clickNotification = (notification: any) => {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService.getItem(StorageKeyENUM.ACCESS_TOKEN)}`
    });
    this.router.navigateByUrl(`/candidates/${notification.candidateId}`);
    this.http.delete(`${SERVER_URL}/user/remove-notification/${notification.id}`, { headers })
      .subscribe(() => {
        this.refresh();
      });
  }

}
