<app-logo [name]="name" class="p-15"></app-logo>

  <div class="vsidebar" style="margin-top: 20px;">
    <mat-nav-list class="vmenu-list-item">
      <app-menu-list-item *ngFor="let item of navItems" [item]="item">
      </app-menu-list-item>
      <div style="margin-top: 50px;">
        <button mat-button (click)="logOut()">
          <i-feather name="log-out" style="margin-right: 8px;"></i-feather>
          Log Out
        </button>
      </div>
    </mat-nav-list>
  </div>
