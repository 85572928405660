// This is for Notifications
export const Notifications: Object[] = [
    {
        bg: 'bg-danger',
        icon: 'home',
        title: 'Add note',
        subject: 'User edit note to the candidate!',
    },
    {
        bg: 'bg-primary',
        icon: 'account_circle',
        title: 'New Candidate',
        subject: 'There is a new candidate!',
    }
];

