import { appInjector } from "../services/injector.service";

export const useMock = (mock?: any) => (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
  if (mock) {
    // eslint-disable-next-line space-before-function-paren
    descriptor.value = function (...args: any) {
      const newRepository: any = appInjector.get(mock, null, undefined);
      const r = newRepository[propertyKey](...args);
      return r;
    };
    return descriptor;
  }
  return descriptor;
};
