import { Component, Input } from '@angular/core';
import { CustomizerService } from 'src/app/services/customizer.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input() name: string | null = '';

  constructor(public customizer: CustomizerService) { }

}
