import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICandidate } from 'src/app/business/entities/candidate/candidate.entity';
import { BaseManager } from 'src/app/business/managers/base.manager';
import { APIS } from 'src/app/constants/api.constant';
import { StorageService } from 'src/app/services/storage/storage.service';
import { candidateMapper } from '../../entities/candidate/candidate.entity.mapper';

@Injectable({
  providedIn: 'root',
})
export class FowardStepManager extends BaseManager {
  constructor(
    protected override httpC: HttpClient,
    protected override storageService: StorageService
  ) {
    super(httpC, storageService);
  }

  fowardStep(id: any) {
    return this.call<ICandidate>(
      APIS.FOWARD_STEP,
      undefined,
      undefined,
      {
        id,
      }
    );
  }

  protected override mapper(res: any) {
    return candidateMapper(res);
  }
}
