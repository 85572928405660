import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import {
  ApplicationRef,
  APP_INITIALIZER,
  DoBootstrap,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomizerService } from './services/customizer.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { InitService } from './services/init.service';
import { setAppInjector } from './services/injector.service';
import { AuthService } from './ui/pages/authentication/auth.service';
import { BlankComponent } from './ui/root/layouts/blank/blank.component';
import { CustomizerComponent } from './ui/root/layouts/full/customizer/customizer.component';
import { FullComponent } from './ui/root/layouts/full/full.component';
import { HorizontalHeaderComponent } from './ui/root/layouts/full/header/horizontal-header/horizontal-header.component';
import { VerticalHeaderComponent } from './ui/root/layouts/full/header/vertical-header/vertical-header.component';
import { LogoComponent } from './ui/root/layouts/full/logo/logo.component';
import { HorizontalSidebarComponent } from './ui/root/layouts/full/sidebar/horizontal-sidebar/horizontal-sidebar.component';
import { HnavbarComponent } from './ui/root/layouts/full/sidebar/horizontal-sidebar/navbar/hnavbar.component';
import { MenuListItemComponent } from './ui/root/layouts/full/sidebar/vertical-sidebar/menu-list-item/menu-list-item.component';
import { VerticalSidebarComponent } from './ui/root/layouts/full/sidebar/vertical-sidebar/vertical-sidebar.component';
import { MaterialModule } from './ui/shared-components/material/material.module';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    VerticalHeaderComponent,
    HorizontalHeaderComponent,
    VerticalSidebarComponent,
    HnavbarComponent,
    MenuListItemComponent,
    HorizontalSidebarComponent,
    CustomizerComponent,
    LogoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    FeatherModule.pick(allIcons),
    FlexLayoutModule,
    BrowserAnimationsModule,
    NgDynamicBreadcrumbModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectCountryModule.forRoot('en'),
  ],
  providers: [
    CustomizerService,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: InitService) => async () => await ds.load(),
      deps: [InitService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    setAppInjector(this.injector);
    appRef.bootstrap(AppComponent);
  }
}
