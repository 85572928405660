import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICandidate } from 'src/app/business/entities/candidate/candidate.entity';
import { APIS } from 'src/app/constants/api.constant';
import { StorageService } from 'src/app/services/storage/storage.service';
import { candidateListMapper } from '../../entities/candidate/candidate.entity.mapper';
import { BaseManager } from '../base.manager';

@Injectable({
  providedIn: 'root'
})
export class GetCandidatesManager extends BaseManager {

  constructor(
    protected override httpC: HttpClient, protected override storageService: StorageService
  ) {
    super(httpC, storageService)
  }

  getCandidates() {
    return this.call<ICandidate[]>(APIS.CANDIDATES_GET, [], {}, {});
  }

  protected override mapper(res: any) {
    return candidateListMapper(res);
  }

}
