// This is for Profile
export const Profile: Object[] = [
  {
    bg: 'danger',
    icon: 'dollar-sign',
    title: 'My Profile',
    subject: 'Account Settings',
  },
  {
    bg: 'success',
    icon: 'shield',
    title: 'My Inbox',
    subject: 'Messages & Emails',
  },
  {
    bg: 'info',
    icon: 'credit-card',
    title: 'My Tasks',
    subject: 'To-do and Daily Tasks',
  }
];
