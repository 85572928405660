import { Injectable } from "@angular/core";
import { StorageKeyENUM } from "./storage.enum";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly TAG = 'StorageService -';

  constructor() { }

  setObject<T>(key: StorageKeyENUM, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
    console.log(`${this.TAG} setObject - KEY: ${key}, VAL: ${JSON.stringify(value)}`);
  }

  getObject<T>(key: StorageKeyENUM) {
    const value = localStorage.getItem(key) ?? '';
    console.log(`${this.TAG} getObject - KEY: ${key}, VAL: ${value}`);
    try {
      return <T>JSON.parse(value);
    } catch (e) {
      console.log(`${this.TAG} getObject - ERR: `, e);
      return null;
    }
  }

  setItem(key: StorageKeyENUM, value: string) {
    localStorage.setItem(key, value);
    console.log(`${this.TAG} setItem - KEY: ${key}, VAL: ${value}`);
  }

  getItem<T>(key: StorageKeyENUM) {
    const value = localStorage.getItem(key)
    console.log(`${this.TAG} getItem - KEY: ${key}, VAL: ${value}`);
    return <T>(value as unknown);
  }

  removeItem(key: StorageKeyENUM) {
    localStorage.removeItem(key);
    console.log(`${this.TAG} removeItem - KEY: ${key}`);
  }

  clear() {
    localStorage.clear();
    console.log(`${this.TAG} clear`);
  }

}