import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../../../../../services/nav.service';
import { navItems } from './navbar/horizontal-sidebar-data';

@Component({
  selector: 'app-horizontal-sidebar',
  templateUrl: './horizontal-sidebar.component.html',
  styleUrls: [],
})
export class HorizontalSidebarComponent {

  navItems = navItems;

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(public navService: NavService, public router: Router, media: MediaMatcher, changeDetectorRef: ChangeDetectorRef) {
    this.mobileQuery = media.matchMedia('(min-width: 1100px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

}
