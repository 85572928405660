import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  private readonly tag = 'InitService -';

  constructor() { }

  async load() {
    console.log(this.tag, 'Load');
    return;
  }

}
