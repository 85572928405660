<h2 class="p-15 mat-title m-b-0">Theme Settings</h2>
<mat-divider></mat-divider>
<mat-nav-list>
  <!-- ============================================================== -->
  <!-- Color Change -->
  <!-- ============================================================== -->
  <h4 class="p-15 m-b-0">Theme colors</h4>
  <ul class="themeColorOption">
    <li *ngFor="let colors of colorOptions" class="{{ colors.value }}">
      <a (click)="setTheme(colors.value)">
        <span
          *ngIf="colors.value === this.customizer.currentTheme; else notactive"
        >
          <i-feather name="disc"></i-feather>
        </span>
        <ng-template #notactive>
          <i-feather name="circle"></i-feather>
        </ng-template>
      </a>
    </li>
  </ul>
  <!-- ============================================================== -->
  <!-- Change Dark -->
  <!-- ============================================================== -->
  <h4 class="p-15 m-b-0">Theme Options</h4>
  <mat-list-item>
    <mat-checkbox
      color="primary"
      [(ngModel)]="this.customizer.dark"
      (click)="toggleDark()"
    >
      Dark
    </mat-checkbox>
  </mat-list-item>
  <mat-list-item>
    <mat-checkbox color="primary" [(ngModel)]="this.customizer.horizontal">
      Horizontal Nav
    </mat-checkbox>
  </mat-list-item>
  <mat-list-item>
    <mat-checkbox color="primary" [(ngModel)]="this.customizer.fixedTopbar">
      Fixed Topbar
    </mat-checkbox>
  </mat-list-item>
  <!-- ============================================================== -->
  <!-- Change RTL -->
  <!-- ============================================================== -->
  <h4 class="p-15 m-b-0">Theme Direction</h4>
  <mat-list-item>
    <mat-slide-toggle
      color="primary"

      (change)="
        this.customizer.dir = this.customizer.dir == 'rtl' ? 'ltr' : 'rtl'
      "
      >RTL
    </mat-slide-toggle>
  </mat-list-item>
</mat-nav-list>
