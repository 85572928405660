
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomizerService {

  dir: any = 'ltr'; // Possible value rtl to make it right to left 
  dark: boolean = false; // Possible value true 
  minisidebar: boolean = false; // Possible value true 
  horizontal: boolean = false; // Possible value true 
  currentTheme: string = 'orangeTheme' // Possible value orangeTheme, blueTheme, greenTheme, purpleTheme, indigoTheme, redTheme 
  fixedTopbar: boolean = true; // Possible value true 

  darkState = new Subject();
  public darktoggleState$ = this.darkState.asObservable()

  horizontalState = new Subject();
  public horizontaltoggle = this.horizontalState.asObservable()

  constructor() {
  }

  toggleDark() {
    this.dark = !this.dark
    this.darkState.next(this.dark);
  }

  toggleHorizontal() {
    this.horizontal = !this.horizontal
    this.horizontalState.next(this.horizontal);
  }

  setCurrentTheme(cvalue: any) {
    this.currentTheme = cvalue
  }

}
