// Declare apis
interface IApi {
  CANDIDATES_GET: IApiReq;
  CANDIDATE_UPDATE: IApiReq;
  CANDIDATE_PAUSE: IApiReq;
  NOTES_POST: IApiReq;
  NOTES_REMOVE: IApiReq;
  NOTES_UPDATE: IApiReq;
  FOWARD_STEP: IApiReq;
  REJECT_CANDIDATE: IApiReq;
  UPDATE_SKILL_PROFILE: IApiReq;
  UPDATE_EXPECTED_LEVEL: IApiReq;
}

export interface IApiReq {
  method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';
  path: string;
  skipTokenized?: boolean;
}

// Implement apis
export const APIS: IApi = {
  CANDIDATES_GET: {
    method: 'POST',
    path: 'candidates',
  },
  CANDIDATE_UPDATE: {
    method: 'PUT',
    path: 'candidates/update/:candidateId',
  },
  CANDIDATE_PAUSE: {
    method: 'POST',
    path: 'candidates/pause/:candidateId',
  },
  UPDATE_SKILL_PROFILE: {
    method: 'POST',
    path: 'candidates/update-skill-profile',
  },
  UPDATE_EXPECTED_LEVEL: {
    method: 'POST',
    path: 'candidates/update-expected-level',
  },
  FOWARD_STEP: {
    path: '/candidates/foward-step/:id',
    method: 'POST',
  },
  REJECT_CANDIDATE: {
    path: '/candidates/reject/:id',
    method: 'POST',
  },
  NOTES_POST: {
    method: 'POST',
    path: 'notes/create/:candidateId',
  },
  NOTES_REMOVE: {
    method: 'DELETE',
    path: 'notes/remove/:noteId',
  },
  NOTES_UPDATE: {
    method: 'POST',
    path: 'notes/update',
  },
};
