// This is for Messages
export const Messages: Object[] = [
    {
        img: '../../../../assets/images/users/1.jpg',
        title: 'Luanch Admin',
        subject: 'Just see the my new admin!',
        time: '9:30 AM'
    },
    {
        img: '../../../../assets/images/users/2.jpg',
        title: 'Event today',
        subject: 'Just a reminder that you have event',
        time: '9:10 AM'
    },
    {
        img: '../../../../assets/images/users/3.jpg',
        title: 'Settings',
        subject: 'You can customize this template as you want',
        time: '9:08 AM'
    },
    {
        img: '../../../../assets/images/users/4.jpg',
        title: 'Pavan kumar',
        subject: 'Just see the my admin!',
        time: '9:00 AM'
    }
];

