import { Component } from '@angular/core';
import { CustomizerService } from './services/customizer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  title = 'Flexy-angular';

  constructor(public customizer: CustomizerService) {}
}
