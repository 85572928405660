import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
  ICandidate,
  IUser,
} from 'src/app/business/entities/candidate/candidate.entity';
import { FilterPayload } from 'src/app/business/managers/candidates/ filter-candidates.manager';
import { CandidatesRepository } from 'src/app/business/repositories/candidates/candidates.repository';
import { environment } from 'src/environments/environment';
import { StepInfo } from './components/step-count/step-count.component';

@Injectable({
  providedIn: 'root',
})
export class CandidatesService {
  loading = new BehaviorSubject(false);
  loadingSelected = new BehaviorSubject(false);
  loadingUpdate = new BehaviorSubject(false);
  loadingFilter = new BehaviorSubject(false);
  candidateSelectedSubject = new BehaviorSubject<ICandidate | null>(null);
  candidatesSubject = new BehaviorSubject<ICandidate[]>([]);
  candidatesBackup: ICandidate[] = [];

  public editDataDetails: any = [];

  statusCandidate = new BehaviorSubject('');
  currentStep = new BehaviorSubject(0);

  users = new BehaviorSubject<IUser[]>([]);
  constructor(
    private candidatesRepository: CandidatesRepository,
    private httpClient: HttpClient
  ) {}

  changeStatusCandidate(message: string) {
    this.statusCandidate.next(message);
  }

  getCandidates() {
    const getCandidatesManager = this.candidatesRepository.getCandidatesCall();
    getCandidatesManager.pipe(map(() => this.loading.next(false)));
    return getCandidatesManager;
  }

  filterCandidates(body: FilterPayload[]) {
    const getCandidatesManager = this.candidatesRepository.filterCandidatesCall(body);
    getCandidatesManager.pipe(map(() => this.loading.next(false)));
    return getCandidatesManager;
  }

  getUsers() {
    this.httpClient
      .get(`${environment.SERVER_URL}/user`)
      .subscribe((v: any) => this.users.next(v));
  }

  updateCandidate(id: any, candidate: any) {
    this.loadingUpdate.next(true);
    const updateCandidateManager = this.candidatesRepository.updateCandidate(
      id,
      candidate
    );

    return updateCandidateManager.pipe(
      map((resp) => {
        this.loadingUpdate.next(false);
        return resp;
      })
    );
  }

  updateSkillProfileLink(body: { userId: string; skillsProfile: string }) {
    const updateSkillProfileLink =
      this.candidatesRepository.updateSkillProfileLink(body);

    return updateSkillProfileLink.pipe(
      map((resp) => {
        this.loadingUpdate.next(false);
        return resp;
      })
    );
  }

  updateExpectedLevel(body: { userId: string; expectedLevel: string }) {
    const updateExpectedLevel =
      this.candidatesRepository.updateExpectedLevel(body);

    return updateExpectedLevel.pipe(
      map((resp) => {
        this.loadingUpdate.next(false);
        return resp;
      })
    );
  }

  pauseCandidate(
    id: any,
    body: { candidateName: string; candidateEmail: string },
    isReconsidered: boolean
  ) {
    this.loadingUpdate.next(true);
    const updateCandidateManager = this.candidatesRepository.pauseCandidate(
      id,
      body
    );

    if (isReconsidered) {
      this.updateCandidate(id, { isReconsidered: false });
    }

    return updateCandidateManager.pipe(
      map((resp) => {
        this.loadingUpdate.next(false);
        return resp;
      })
    );
  }

  fowardStep(id: number) {
    return this.candidatesRepository.fowardStep(id);
  }

  reject(id: number, isReconsidered: boolean) {
    console.log(id, isReconsidered);
    const rta = this.candidatesRepository.rejectCandidate(id);

    if (isReconsidered) {
      console.log('update');
      const rta = this.updateCandidate(id, { isReconsidered: false });
      console.log(rta);
    }
    return rta;
  }

  getCandidateBy(qParams: any) {
    this.loading.next(true);
    const getCandidateManager =
      this.candidatesRepository.getCandidateCall(qParams);
    getCandidateManager.pipe(map(() => this.loading.next(false)));
    return getCandidateManager;
  }
}
