import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IApiReq } from 'src/app/constants/api.constant';
import { StorageKeyENUM } from 'src/app/services/storage/storage.enum';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';

export class BaseManager {
  private readonly TAG = 'BaseManager -';

  constructor(
    protected httpC: HttpClient,
    protected storageService: StorageService
  ) {}

  protected call<T>(
    apiReq: IApiReq,
    body = {},
    qParams = {},
    routeParams = {}
  ): Observable<T> {
    const options: any = {};

    if (!apiReq.skipTokenized) {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.storageService.getItem(
          StorageKeyENUM.ACCESS_TOKEN
        )}`,
      });

      options.headers = headers;
    }

    if (Object.keys(body).length) {
      options.body = body;
    } else {
      options.body = []
    }

    if (Object.keys(qParams)) {
      const params = new HttpParams({ fromObject: qParams });
      options.params = params;
    }

    const url = this.buildUrl(apiReq.path, routeParams);
    console.log(this.TAG, 'call: ', url);

    return this.httpC.request<T>(apiReq.method, url, options).pipe(
      map((res) => {
        return this.mapper(res);
      })
    );
 
  }

  protected mapper(res: any) {
    return res;
  }

  private buildUrl(path: string, routeParams: any = {}) {
    const serverUrl = environment.SERVER_URL;
    path = this.addRouteParams(path, routeParams);
    return `${serverUrl}/${path}`;
  }

  private addRouteParams(path: string, routeParams: any) {
    if (Object.keys(routeParams)) {
      const keys = Object.keys(routeParams);
      keys.forEach((key) => {
        path = path.replace(':' + key, String(routeParams[key]));
      });
    }

    return path;
  }
}
