import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WebsocketService } from 'src/app/services/websocket.service';
import { AuthService } from 'src/app/ui/pages/authentication/auth.service';
import { navItems } from './sidebar-data';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: [],
})
export class VerticalSidebarComponent {

  navItems = navItems;
  name: string = '';

  constructor(
    private authService: AuthService,
    private ws: WebsocketService
  ) {
    this.authService.user.subscribe( ({ user }) => {
      this.name = user ? user.name : ''
    })
  }

  logOut() {
    this.authService.logOut();
    this.ws.removeConnections();
  }
  

}
