import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import { StorageKeyENUM } from '../services/storage/storage.enum';
import { StorageService } from '../services/storage/storage.service';
import { AuthService } from '../ui/pages/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private routes: Router, private authService: AuthService,
    private storageService: StorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      const tokenExist = this.storageService.getItem<string>(StorageKeyENUM.ACCESS_TOKEN);
      if (tokenExist) {
        this.authService.checkToken(tokenExist)
          .subscribe(resp => {
            if (resp.ok) {
              this.authService.user.next(resp);
              this.storageService.setItem(StorageKeyENUM.USER_ID, String(resp.user?.id));
              resolve(resp.ok);
            } else {
              this.routes.navigateByUrl('/auth/login');
              resolve(false);
            }
          });
      } else {
        this.routes.navigateByUrl('/auth/login');
        resolve(false);
      }
    });
  }
}

