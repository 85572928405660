import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UI_ROUTES } from './constants/ui.routes.constant';
import { AuthGuard } from './guards/auth.guard';
import { BlankComponent } from './ui/root/layouts/blank/blank.component';
import { FullComponent } from "./ui/root/layouts/full/full.component";

const routes: Routes = [

  {
    path: UI_ROUTES.auth,
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./ui/pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
      }
    ]
  },
  {
    path: "careersDetails",
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./ui/pages/candidates/pages/careersDetails/careers-detail.module').then((m) => m.CareersDetailModuleV2),
      }
    ]
  },
  {
    path: UI_ROUTES.careers,
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./ui/pages/careersv2/careers.module').then((m) => m.CareersModuleV2),
      }
    ]
  },

  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: UI_ROUTES.candidates,
        loadChildren: () => import('./ui/pages/candidates/candidates.module').then((m) => m.CandidatesModule),
      },
      {
        path: '**',
        redirectTo: UI_ROUTES.candidates
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
