import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { useMock } from 'src/app/decorators/mock.decorator';
import { environment } from 'src/environments/environment';
import { ICandidate } from '../../entities/candidate/candidate.entity';
import { FilterCandidatesManager, FilterPayload } from '../../managers/candidates/ filter-candidates.manager';
import { FowardStepManager } from '../../managers/candidates/foward-step.manager';
import { GetCandidateManager } from '../../managers/candidates/get-candidate.manager';
import { GetCandidatesManager } from '../../managers/candidates/get-candidates.manager';
import { PauseCandidateManager } from '../../managers/candidates/pause-candidate.manager';
import { RejectCandidateManager } from '../../managers/candidates/reject-candidate.manager';
import { UpdateCandidateManager } from '../../managers/candidates/update-candidate.manager';
import { UpdateExpectedLevelManager } from '../../managers/candidates/update-expected-level.manager';
import { UpdateSkillProfileManager } from '../../managers/candidates/update-skill-profile.manager';
import { ICandidatesRepository } from './candidates.interface.repository';
import { CandidatesMockRepository } from './candidates.mock.repository';

@Injectable({
  providedIn: 'root',
})
export class CandidatesRepository implements ICandidatesRepository {
  constructor(
    private getCandidateManager: GetCandidateManager,
    private getCandidatesManager: GetCandidatesManager,
    private updateCandidateManager: UpdateCandidateManager,
    private rejectCandidateManager: RejectCandidateManager,
    private fowardStepManager: FowardStepManager,
    private updateSkillProfile: UpdateSkillProfileManager,
    private updateExpectedLevelManager: UpdateExpectedLevelManager,
    private filterCandidateManager: FilterCandidatesManager,

    private pauseCandidateManager: PauseCandidateManager
  ) {}

  fowardStep = (id: any): Observable<ICandidate> => {
    return this.fowardStepManager.fowardStep(id);
  };

  rejectCandidate = (id: any): Observable<ICandidate> => {
    return this.rejectCandidateManager.rejectCandidate(id);
  };

  updateCandidate = (
    id: any,
    candidate: Partial<ICandidate>
  ): Observable<ICandidate> => {
    return this.updateCandidateManager.updateCandidate(id, candidate);
  };

  updateSkillProfileLink = (body: { userId: string; skillsProfile: string }) => {
    return this.updateSkillProfile.updateSkillProfile(body);
  };

  updateExpectedLevel = (body: { userId: string, expectedLevel: string }) => {
    return this.updateExpectedLevelManager.updateExpectedLevel(body);
  }

  pauseCandidate = (
    id: any,
    body: { candidateName: string; candidateEmail: string }
  ): Observable<ICandidate> => {
    return this.pauseCandidateManager.pauseCandidate(id, body);
  };

  @useMock(environment.useMock ? CandidatesMockRepository : null)
  getCandidateCall(qParams: any) {
    return this.getCandidateManager.getCandidate(qParams);
  }

  @useMock(environment.useMock ? CandidatesMockRepository : null)
  getCandidatesCall() {
    return this.getCandidatesManager.getCandidates();
  }

  filterCandidatesCall(body: FilterPayload[]) {
    return this.filterCandidateManager.filterCandidate(body);
  }
}
