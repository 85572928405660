<!-- ============================================================== -->
<!-- Sidebar Toggle button - -->
<!-- ============================================================== -->
<button
  type="button"
  mat-button
  mat-icon-button
  (click)="sidebartoggle.toggle()"
>
  <i-feather class="feather-20 text-light" name="menu"></i-feather>
</button>

<span fxFlex></span>

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<button
  [matMenuTriggerFor]="notification"
  mat-button
  mat-icon-button
  [class]="getBtnNotificationClass()"
>
  <i-feather class="feather-20 text-light" name="bell"></i-feather>
  <div class="notify">
    <span *ngIf="notifications.length > 0" class="point bg-primary"></span>
  </div>
</button>
<mat-menu #notification="matMenu" class="topbardd">
  <mat-action-list class="m-b-10">
    <h4 class="ddheadtitle m-0 m-b-15 m-r-10 hstack align-items-center">
      Notifications
      <div class="m-l-10">
        <mat-chip-list>
          <mat-chip *ngIf="notifications.length > 0" color="primary" selected>{{ notifications.length }} new</mat-chip></mat-chip-list
        >
      </div>
    </h4>
    <ng-container *ngFor="let notification of notifications; last as last">
        <mat-list-item
          (click)="clickNotification(notification)"
        >
          <div class="text-white {{ notification.bg }}" mat-list-icon>
            <mat-icon>
              {{ notification.icon }}
            </mat-icon>
          </div>
    
          <h6 mat-line class="ddtitle">{{ notification.title }}</h6>
          <span matLine></span>
          <div mat-line class="text-muted">{{ notification.subject }}</div>
          <mat-divider *ngIf="!last"></mat-divider>
        </mat-list-item>
    </ng-container>
  </mat-action-list>
</mat-menu>
