import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ICandidate } from '../../entities/candidate/candidate.entity';
import { GetCandidatesManager } from '../../managers/candidates/get-candidates.manager';
import { ICandidatesRepository } from './candidates.interface.repository';

@Injectable({
  providedIn: 'root',
})
export class CandidatesMockRepository {
  constructor(private getCandidatesManager: GetCandidatesManager) {}
  

  updateCandidate = (
    id: any,
    candidate: Partial<ICandidate>
  ): Observable<ICandidate> => {
    return from(
      new Promise<ICandidate>((resolve) => {
        const candidate = {
          id: 142,
          name: 'nicolas alberto alvarez',
          email: 'nicolas1177alvarez@gmail.com',
          phone: '37400252',
          location: 'arg',
          skills: '["a","b","c"]',
          hearAboutUs: '',
          linkedin: '',
          favoriteBook: '',
          availableStartDate: '',
          desiredHourlyCompensation: 30,
          hoursAvailable: 0,
          otherSkill: '',
          pieceOfSoftware: '',
          status: 'progress',
          work: '',
          actualFeedback: '',
          step: 3,
          interviewNotes: '',
          softwareSkills: [],
          languages: [],
          industryExperience: [],
          createdAt: '2022-09-30',
          updatedAt: '2022-09-30',
          typicalHours: '',
          notes: [
            {
              id: 202,
              body: 'Candidate is ready to take the Smart Task 0909009',
              title: 'Pre-determined criteria and Software skills applied',
              color: 'success',
              nameLastUpdate: '',
              isAutomatic: true,
              createdAt: '2022-09-30',
              updatedAt: '2022-10-18',
              candidateId: 142,
              stepNote: 2,
            },
            {
              id: 203,
              body: 'This is a new note',
              title: 'New Note',
              color: 'success',
              nameLastUpdate: 'Nicolas',
              isAutomatic: false,
              createdAt: '2022-10-18',
              updatedAt: '2022-10-18',
              candidateId: 142,
            },
          ],
          step3: { file: '', date: new Date() },
          answersVoxerInterview: [],
          videointerview: {} as any,
          smartTaskFinishDate: '',
          startVoxerInterview: '',
          finishVoxerInterview: '',
          startAssessmentEmail: '',
          finishAssessmentEmail: '',
          startSendOffer: '',
          finishSendOffer: '',
          wonScore: 0,
          agreement: false,
          liabilityInsurance: false,
          backgroundCheck: false,
        } as unknown as ICandidate;

        resolve(candidate);
      })
    );
  };

  rejectCandidate = (
    id: any
  ): Observable<ICandidate> => {
    return from(
      new Promise<ICandidate>((resolve) => {
        const candidate = {
          id: 142,
          name: 'nicolas alberto alvarez',
          email: 'nicolas1177alvarez@gmail.com',
          phone: '37400252',
          location: 'arg',
          skills: '["a","b","c"]',
          hearAboutUs: '',
          linkedin: '',
          favoriteBook: '',
          availableStartDate: '',
          desiredHourlyCompensation: 30,
          hoursAvailable: 0,
          otherSkill: '',
          pieceOfSoftware: '',
          status: 'progress',
          work: '',
          actualFeedback: '',
          step: 3,
          interviewNotes: '',
          softwareSkills: [],
          languages: [],
          industryExperience: [],
          createdAt: '2022-09-30',
          updatedAt: '2022-09-30',
          typicalHours: '',
          notes: [
            {
              id: 202,
              body: 'Candidate is ready to take the Smart Task 0909009',
              title: 'Pre-determined criteria and Software skills applied',
              color: 'success',
              nameLastUpdate: '',
              isAutomatic: true,
              createdAt: '2022-09-30',
              updatedAt: '2022-10-18',
              candidateId: 142,
              stepNote: 2,
            },
            {
              id: 203,
              body: 'This is a new note',
              title: 'New Note',
              color: 'success',
              nameLastUpdate: 'Nicolas',
              isAutomatic: false,
              createdAt: '2022-10-18',
              updatedAt: '2022-10-18',
              candidateId: 142,
            },
          ],
          step3: { file: '', date: new Date() },
          answersVoxerInterview: [],
          videointerview: {} as any,
          smartTaskFinishDate: '',
          startVoxerInterview: '',
          finishVoxerInterview: '',
          startAssessmentEmail: '',
          finishAssessmentEmail: '',
          startSendOffer: '',
          finishSendOffer: '',
          wonScore: 0,
          agreement: false,
          liabilityInsurance: false,
          backgroundCheck: false,
        } as unknown as ICandidate;

        resolve(candidate);
      })
    );
  };

  fowardStep = (
    id: any
  ): Observable<ICandidate> => {
    return from(
      new Promise<ICandidate>((resolve) => {
        const candidate = {
          id: 142,
          name: 'nicolas alberto alvarez',
          email: 'nicolas1177alvarez@gmail.com',
          phone: '37400252',
          location: 'arg',
          skills: '["a","b","c"]',
          hearAboutUs: '',
          linkedin: '',
          favoriteBook: '',
          availableStartDate: '',
          desiredHourlyCompensation: 30,
          hoursAvailable: 0,
          otherSkill: '',
          pieceOfSoftware: '',
          status: 'progress',
          work: '',
          actualFeedback: '',
          step: 3,
          interviewNotes: '',
          softwareSkills: [],
          languages: [],
          industryExperience: [],
          createdAt: '2022-09-30',
          updatedAt: '2022-09-30',
          typicalHours: '',
          notes: [
            {
              id: 202,
              body: 'Candidate is ready to take the Smart Task 0909009',
              title: 'Pre-determined criteria and Software skills applied',
              color: 'success',
              nameLastUpdate: '',
              isAutomatic: true,
              createdAt: '2022-09-30',
              updatedAt: '2022-10-18',
              candidateId: 142,
              stepNote: 2,
            },
            {
              id: 203,
              body: 'This is a new note',
              title: 'New Note',
              color: 'success',
              nameLastUpdate: 'Nicolas',
              isAutomatic: false,
              createdAt: '2022-10-18',
              updatedAt: '2022-10-18',
              candidateId: 142,
            },
          ],
          step3: { file: '', date: new Date() },
          answersVoxerInterview: [],
          videointerview: {} as any,
          smartTaskFinishDate: '',
          startVoxerInterview: '',
          finishVoxerInterview: '',
          startAssessmentEmail: '',
          finishAssessmentEmail: '',
          startSendOffer: '',
          finishSendOffer: '',
          wonScore: 0,
          agreement: false,
          liabilityInsurance: false,
          backgroundCheck: false,
        } as unknown as ICandidate;

        resolve(candidate);
      })
    );
  };

  getCandidateCall(qParams: any) {
    return from(
      new Promise<ICandidate>((resolve) => {
        const candidate = {
          id: 142,
          name: 'nicolas alberto alvarez',
          email: 'nicolas1177alvarez@gmail.com',
          phone: '37400252',
          location: 'arg',
          skills: '["a","b","c"]',
          hearAboutUs: '',
          linkedin: '',
          favoriteBook: '',
          availableStartDate: '',
          desiredHourlyCompensation: 30,
          hoursAvailable: 0,
          otherSkill: '',
          pieceOfSoftware: '',
          status: 'progress',
          work: '',
          actualFeedback: '',
          step: 3,
          interviewNotes: '',
          softwareSkills: [],
          languages: [],
          industryExperience: [],
          createdAt: '2022-09-30',
          updatedAt: '2022-09-30',
          typicalHours: '',
          notes: [
            {
              id: 202,
              body: 'Candidate is ready to take the Smart Task 0909009',
              title: 'Pre-determined criteria and Software skills applied',
              color: 'success',
              nameLastUpdate: '',
              isAutomatic: true,
              createdAt: '2022-09-30',
              updatedAt: '2022-10-18',
              candidateId: 142,
              stepNote: 2,
            },
            {
              id: 203,
              body: 'This is a new note',
              title: 'New Note',
              color: 'success',
              nameLastUpdate: 'Nicolas',
              isAutomatic: false,
              createdAt: '2022-10-18',
              updatedAt: '2022-10-18',
              candidateId: 142,
            },
          ],
          step3: { file: '', date: new Date() },
          answersVoxerInterview: [],
          videointerview: {} as any,
          smartTaskFinishDate: '',
          startVoxerInterview: '',
          finishVoxerInterview: '',
          startAssessmentEmail: '',
          finishAssessmentEmail: '',
          startSendOffer: '',
          finishSendOffer: '',
          wonScore: 0,
          agreement: false,
          liabilityInsurance: false,
          backgroundCheck: false,
        } as unknown as ICandidate;

        resolve(candidate);
      })
    );
  }

  getCandidatesCall() {
    return this.getCandidatesManager.getCandidates();
  }
}
