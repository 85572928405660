import { Injectable } from '@angular/core';
import { appInjector } from './injector.service';
import { StorageKeyENUM } from './storage/storage.enum';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  removeHandlers: any[] = [];

  private static URL = 'wss://w9bb0s65nd.execute-api.us-west-2.amazonaws.com/v1';
  private static _instanceSocket: WebSocket | null = null;
  private static _storageService: StorageService | null = null;

  public static getInstance() {
    if (!this._instanceSocket) {
      this._storageService = appInjector.get(StorageService);
      this._instanceSocket = new WebSocket(this.URL);
      this._instanceSocket.addEventListener('open', () => {
        console.log('open');
        this._instanceSocket!.send(JSON.stringify({ action: 'who_ami', userId: this._storageService!.getItem(StorageKeyENUM.USER_ID) }));
      });
      this._instanceSocket.addEventListener('close', () => console.log('close'));
    }
    return this._instanceSocket;
  }

  public listen(action: string, callback: Function) {
    const socket = WebsocketService.getInstance();
    const handleMessage = ({ data }: any) => {
      const dataParsed = JSON.parse(data);
      if (action === dataParsed.action) {
        callback(data);
      }
    }
    socket.addEventListener('message', handleMessage);

    const removeHandler = () => {
      socket?.removeEventListener('message', handleMessage);
      socket?.close();
    };

    this.removeHandlers.push(removeHandler);
  }

  public removeConnections = () => {
    for (const removeHandler of this.removeHandlers) {
      removeHandler();
    }
  }

}
