import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIS } from 'src/app/constants/api.constant';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ICandidate } from '../../entities/candidate/candidate.entity';
import { candidateListMapper } from '../../entities/candidate/candidate.entity.mapper';
import { BaseManager } from '../base.manager';

export interface FilterPayload {
  dateField: string;
  value: string  | Date | undefined;
  operation: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilterCandidatesManager extends BaseManager {
  constructor(
    protected override httpC: HttpClient,
    protected override storageService: StorageService
  ) {
    super(httpC, storageService);
  }

  filterCandidate(body: FilterPayload[]) {
    return this.call<ICandidate[]>(APIS.CANDIDATES_GET, body, {}, {});
  }

  protected override mapper(res: any) {
    return candidateListMapper(res);
  }
}
