import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StorageKeyENUM } from 'src/app/services/storage/storage.enum';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from '../../../../environments/environment';

type Login = { email: string; password: string };

type CheckToken = {
  ok: boolean;
  user?: {
    email: string;
    name: string;
    roles: string[];
    id: number;
  };
};

const { SERVER_URL } = environment;
@Injectable()
export class AuthService {
  user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}

  login({ email, password }: Login) {
    const credentials = window.btoa(`${email}:${password}`);
    const auth = { Authorization: `Basic ${credentials}` };
    return this.http.post(
      `${SERVER_URL}/auth/login`,
      {},
      {
        headers: auth,
      }
    );
  }

  logOut() {
    this.storageService.removeItem(StorageKeyENUM.ACCESS_TOKEN);
    // window.location.reload();
    return this.router.navigateByUrl('/auth/login');
  }

  checkToken(token: string) {
    return this.http.post<CheckToken>(`${SERVER_URL}/auth/token`, { token });
  }
}
